import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../components/context/themeContext/ThemeContext";
import AddViewUsers from "../AcountInfo/modals/ViewUsers.js/AddViewUsers";
import { useSelector } from "react-redux";
import ApiLink from "../../../components/api/ApiLink";
import { useSelectedCompany } from "../../../components/context/apiContext/SelectedCompanyContext";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function ViewUsers() {
  const { companyName } = useParams();

  const swal = require("sweetalert2");

  const [loading, setLoading] = useState(false);

  const [selectedCompanyName, setSelectedCompanyName] = useState({
    companyName: null,
    company_id: null,
    city: null,
    state: null,
    zip: null,
    type: null,
  });
  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);
  const is_read_only = useSelector((state) => state.auth.is_read_only);
  const [modalLoading, setModalLoading] = useState(false);
  // const is_read_only = useState(0);
  const {
    color,
    textColor,
    backgroundColor,
    tableHeaderTextColor,
    tableHeaderColor,
    sideBarOpen,
  } = useContext(ThemeContext);
  const [editUsers, setEditUsers] = useState(false);
  const [resetPass, setResetPass] = useState(false);
  const [emailReset, setEmailReset] = useState(false);

  const [userListData, setUserListData] = useState([]);
  const [locationListData, setLocationListData] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [viewUserLocationNames, setViewUserLocationNames] = useState([]);
  const userType = useSelector((state) => state.auth.userType);
  const companyId = useSelector((state) => state.auth.company_id);

  const [selectedStatus, setSelectedStatus] = useState("1");
  const [getHandleIconData, setGetHandleIconData] = useState({
    usersid: null,
    email: null,
    first_name: null,
    last_name: null,
    phone: null,
    usertype: null,
    system_notifications: null,
    is_read_only: null,
    alerts_access: null,
    alerts: null,
    company_location_id: null,
    company_id: null,
  });

  const [noUserMessage, setNoUserMessage] = useState("");

  const [selectedLocationIds, setSelectedLocationIds] = useState([]);

  const [resetPassDetails, setResetPassDetails] = useState({
    resetPassEmail: null,
    resetPassfirstName: null,
    resetPasslastName: null,
    resetPassUserID: null,
    resetPassVerifyCode: null,
  });

  // EDIT STATES

  useEffect(() => {
    const fetchCompanyList = async () => {
      if (userType !== 1) {
        setSelectedCompanyName({
          companyName: null,
          company_id: companyId,
          city: null,
          state: null,
          zip: null,
          type: null,
        });
        return;
      }
      try {
        const response = await ApiLink.post("/api/company-info", {
          email: email,
          password: password,
        });

        if (response.status === 200) {
          // const companies = response.data.Data;
          // const matchingCompany = companies.find(
          //   (company) => String(company.company_id) === String(companyName)
          // );
          const matchingCompany = response.data.Data.Company;
          console.log("matchingCompany", companyName);

          if (matchingCompany) {
            console.log("Matching company found:", matchingCompany);
            setSelectedCompanyName({
              companyName: matchingCompany.name,
              company_id: matchingCompany.company_id,
              city: matchingCompany.city,
              state: matchingCompany.state,
              zip: matchingCompany.zip,
              type: matchingCompany.type,
            });
          } else {
            console.log(
              "No matching company found for company_id:",
              companyName
            );
            setSelectedCompanyName({
              companyName: null,
              company_id: null,
              city: null,
              state: null,
              zip: null,
              type: null,
            });
          }
        }
      } catch (error) {
        console.error("Error fetching company list:", error);
      }
    };

    fetchCompanyList();
  }, [companyName, email, password]);

  const fetchUserList = async () => {
    setLoading(true);

    try {
      const response = await ApiLink.post("/api/user-list", {
        email: email,
        password: password,
        company_id: selectedCompanyName.company_id,
        status: selectedStatus,
      });

      if (response.status === 200) {
        if (Array.isArray(response.data.Data)) {
          setUserListData(response.data.Data);
        } else if (response.data.Data.Message) {
          setNoUserMessage(response.data.Data.Message);
          setUserListData([]);
        }
      } else {
        console.error("Error:", response.data);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (selectedCompanyName.company_id) {
      fetchUserList();
    }
  }, [email, password, selectedCompanyName.company_id, selectedStatus]);

  useEffect(() => {
    const fetchLocationList = async () => {
      setLoading(true);
      try {
        const response = await ApiLink.post("/api/company-locations", {
          email,
          password,
          company_id: selectedCompanyName.company_id,
        });

        if (response.status === 200) {
          if (Array.isArray(response.data.Data.Company_location)) {
            setLocationListData(response.data.Data.Company_location);

            // Initialize selectedLocationIds based on getHandleIconData
            if (getHandleIconData.company_location_id) {
              setSelectedLocationIds(
                JSON.parse(getHandleIconData.company_location_id)
              );
            } else {
              // If company_location_id is null, select all locations
              setSelectedLocationIds(
                response.data.Data.Company_location.map((location) =>
                  location.company_location_id.toString()
                )
              );
            }
          }
        } else {
          console.error("Error:", response.data);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedCompanyName.company_id) {
      fetchLocationList();
    }
  }, [
    email,
    password,
    selectedCompanyName.company_id,
    getHandleIconData.company_location_id,
  ]);

  const renderCompanyLocationNames = (companyLocationId) => {
    // Check if companyLocationId is null
    if (!companyLocationId) {
      return "All Locations";
    }

    // Parse the company_location_id string into an array
    const locationIds = JSON.parse(companyLocationId);

    // Find the names of the locations based on the IDs
    const locationNames = locationListData
      .filter((location) =>
        locationIds.includes(location.company_location_id.toString())
      )
      .map((location) => (
        <Link
          key={location.company_location_id}
          to={`/location/${location.company_location_id}`}
          className="hover:underline cursor-pointer"
        >
          <span
            key={location.company_location_id}
            className="hover:underline cursor-pointer"
            // onClick={() =>
            //   handleLocationClick(
            //     location.company_location_id,
            //     location.name,
            //     location.company_id,
            //     location.city,
            //     location.state,
            //     location.zip,
            //     location.address_line1
            //   )
            // }
          >
            {location.name}
          </span>
        </Link>
      ));

    // Return the location names as div elements for column display
    return locationNames.map((nameElement, index) => (
      <div key={index}>{nameElement}</div>
    ));
  };

  const toggleResetPass = (first_name, last_name, usersid, emailResetPass) => {
    swal
      .fire({
        title: "Do you want to send a password reset link to this user?",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#FF0000",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiLink.post(
              "/api/send-password-reset-link",
              {
                email: email,
                password: password,
                usersid: usersid,
              }
            );

            if (response.status === 200) {
              console.log(
                "Password reset link sent successfully",
                response.data.Data
              );

              setResetPassDetails({
                resetPassEmail: emailResetPass,
                resetPassfirstName: first_name,
                resetPasslastName: last_name,
                resetPassUserID: usersid,
                resetPassVerifyCode: response.data.Data.verification_code,
              });
              swal.fire({
                title: "Password Reset Link Sent Successfully",
                icon: "success",
                toast: true,
                timer: 4000,
                position: "top-right",
                timerProgressBar: true,
                showConfirmButton: false,
              });
              setResetPass(true);
            }
          } catch (error) {
            swal.fire({
              title: "Error sending reset link",
              icon: "error",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        }
      });
  };

  const toggleEmailReset = () => {
    setEmailReset(true);
  };

  const toggleEditUsers = () => {
    setEditUsers(true);
  };

  const closeEditUsers = () => {
    setEditUsers(false);
  };

  const handleResetKeySubmit = () => {};

  const handEditSubmit = async (event) => {
    event.preventDefault();

    const data = {
      email: email, // for Authentication
      password: password, // for Authentication
      usersid: getHandleIconData.usersid
        ? String(getHandleIconData.usersid)
        : null,
      company_id: getHandleIconData.company_id
        ? String(getHandleIconData.company_id)
        : null,
      user_email: getHandleIconData.email || null,
      phone: getHandleIconData.phone || "",
      system_notifications: getHandleIconData.system_notifications
        ? String(getHandleIconData.system_notifications)
        : "0",
      usertype: getHandleIconData.usertype
        ? String(getHandleIconData.usertype)
        : "0",
      first_name: getHandleIconData.first_name || "",
      last_name: getHandleIconData.last_name || "",
      alerts_access: getHandleIconData.alerts_access
        ? String(getHandleIconData.alerts_access)
        : "0",
      is_read_only: getHandleIconData.is_read_only
        ? String(getHandleIconData.is_read_only)
        : "0",
      alerts: (() => {
        try {
          const parsedAlerts = JSON.parse(getHandleIconData.alerts);
          return Array.isArray(parsedAlerts) && parsedAlerts.length === 0
            ? null
            : parsedAlerts;
        } catch (e) {
          return null;
        }
      })(),
      company_location_id: (() => {
        try {
          const parsedLocations = JSON.parse(
            getHandleIconData.company_location_id
          );
          return Array.isArray(parsedLocations) && parsedLocations.length === 0
            ? null
            : parsedLocations;
        } catch (e) {
          return null;
        }
      })(),
    };
    console.log("Data being sent:", data);

    swal
      .fire({
        title: "Do you want to update this user?",
        showCancelButton: true,
        confirmButtonText: "Save",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#FF0000",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiLink.post("/api/update-user", data);
            console.log("response: ", response);
            if (response.status === 200) {
              try {
                console.log("getHandleIconData: ", getHandleIconData);
                let NewData = {
                  email: getHandleIconData.email,
                  alerts_access: getHandleIconData.alerts_access,
                  first_name: getHandleIconData.first_name,
                  last_name: getHandleIconData.last_name,
                  phone: getHandleIconData.phone,
                  usertype: USER_TYPES[getHandleIconData.usertype],
                  alerts: JSON.parse(getHandleIconData.alerts),
                  system_notifications: getHandleIconData.system_notifications,
                  is_read_only: getHandleIconData.is_read_only,
                  recordid: getHandleIconData.usersid,
                };
                console.log("NewData: ", NewData);
                const zohoResponse = await ApiLink.post(
                  "/api/zoho-crm-update-contacts",
                  NewData
                );
                console.log("Zoho Response: ", zohoResponse.data);
                if (zohoResponse.data.data[0].code === "SUCCESS") {
                  console.log("User updated successfully:", response.data);
                  swal.fire({
                    title: "User Updated Successfully",
                    icon: "success",
                    toast: true,
                    timer: 4000,
                    position: "top-right",
                    timerProgressBar: true,
                    showConfirmButton: false,
                  });
                  closeEditUsers(); // Close the modal
                  fetchUserList();
                }
              } catch (error) {
                console.error("Error updating user in Zoho:", error);
              }
            } else {
              closeEditUsers();
              console.error("Error updating user:", response.data);
            }
          } catch (error) {
            console.error("Network error:", error);
          }
        }
      });
  };

  const handleEmailResetSubmit = () => {};

  const getColorCode = (color) => {
    switch (color) {
      case "blue":
        return "#1c64f2";
      case "green":
        return "#057a55";
      case "purple":
        return "#7e3af2";
      case "red":
        return "#FF0000";
      case "yellow":
        return "#9F580A";
      case "pink":
        return "#D61F69";
      default:
        return "#057a55";
    }
  };

  const USER_TYPES = {
    1: "Super Admin",
    2: "Account Admin",
    4: "Account User",
  };

  const handleEditIcon = (
    usersid,
    email,
    first_name,
    last_name,
    phone,
    usertype,
    system_notifications,
    is_read_only,
    alerts_access,
    alerts,
    company_location_id,
    company_id
  ) => {
    // console.log("usersid:", usersid);
    // console.log("email:", email);
    // console.log("first_name:", first_name);
    // console.log("last_name:", last_name);
    // console.log("phone:", phone);
    // console.log("usertype:", usertype);
    // console.log("system_notifications:", system_notifications);
    // console.log("is_read_only:", is_read_only);
    // console.log("alerts_access:", alerts_access);
    // console.log("alerts:", alerts);
    // console.log("company_location_id:", company_location_id);
    // console.log("company_id:", company_id);

    setGetHandleIconData({
      usersid: usersid,
      email: email,
      first_name: first_name,
      last_name: last_name,
      phone: phone,
      usertype: usertype,
      system_notifications: system_notifications,
      is_read_only: is_read_only,
      alerts_access: alerts_access,
      alerts: alerts
        ? JSON.parse(alerts).length === 0
          ? null
          : JSON.parse(alerts)
        : null,
      company_location_id: company_location_id
        ? JSON.parse(company_location_id).length === 0
          ? null
          : JSON.parse(company_location_id)
        : null,
      company_id: company_id,
    });
  };

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/\D/g, "");

    if (phoneNumber.length <= 3) {
      return phoneNumber;
    } else if (phoneNumber.length <= 6) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    } else {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone") {
      const formattedPhoneNumber = formatPhoneNumber(value);
      setGetHandleIconData((prev) => ({
        ...prev,
        [name]: formattedPhoneNumber,
      }));
    } else {
      setGetHandleIconData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAlertChange = (abbr) => {
    setGetHandleIconData((prev) => {
      let currentAlerts;

      // Check if prev.alerts is a valid JSON string or an array
      if (Array.isArray(prev.alerts)) {
        currentAlerts = prev.alerts;
      } else if (typeof prev.alerts === "string" && prev.alerts.trim() !== "") {
        try {
          currentAlerts = JSON.parse(prev.alerts);
        } catch (error) {
          currentAlerts = [];
        }
      } else {
        currentAlerts = [];
      }

      // Toggle the selected alert
      if (currentAlerts.includes(abbr)) {
        currentAlerts = currentAlerts.filter((alert) => alert !== abbr);
      } else {
        currentAlerts.push(abbr);
      }

      // Update the state with the new alerts array as a JSON string
      return { ...prev, alerts: JSON.stringify(currentAlerts) };
    });
  };

  const renderCheckboxes = (checkedLocationIds) => {
    // Ensure checkedLocationIds and locationListData are arrays to avoid errors
    const validCheckedLocationIds = Array.isArray(checkedLocationIds)
      ? checkedLocationIds
      : [];
    const validLocationListData = Array.isArray(locationListData)
      ? locationListData
      : [];

    return validLocationListData.map((locationlistEdit) => {
      const locationIdStr = locationlistEdit.company_location_id.toString();
      const isChecked = validCheckedLocationIds.includes(locationIdStr);

      return (
        <div
          key={locationlistEdit.company_location_id}
          className="flex items-center mb-2"
        >
          <input
            id={`location-${locationlistEdit.company_location_id}`}
            type="checkbox"
            value={locationlistEdit.company_location_id}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            checked={isChecked}
            onChange={() =>
              handleCheckboxChange(locationlistEdit.company_location_id)
            }
          />
          <label
            htmlFor={`location-${locationlistEdit.company_location_id}`}
            className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 w-48"
          >
            {locationlistEdit.name}
          </label>
        </div>
      );
    });
  };

  const handleCheckboxChange = (locationId) => {
    setSelectedLocationIds((prevSelectedIds) => {
      // Toggle selection: add if not included, remove if included
      const newSelectedIds = prevSelectedIds.includes(locationId.toString())
        ? prevSelectedIds.filter((id) => id !== locationId.toString())
        : [...prevSelectedIds, locationId.toString()];

      // Update getHandleIconData with the JSON string of new selected IDs
      setGetHandleIconData((prev) => ({
        ...prev,
        company_location_id: JSON.stringify(newSelectedIds),
      }));

      return newSelectedIds;
    });
  };

  useEffect(() => {
    if (editUsers) {
      setModalLoading(true);
      const timer = setTimeout(() => {
        setModalLoading(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [editUsers]);

  const toggleBodyScroll = (disable) => {
    if (disable) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    toggleBodyScroll(editUsers || resetPass || emailReset);
    return () => toggleBodyScroll(false);
  }, [editUsers, resetPass, emailReset]);

  const handleDeleteUser = (first_name, last_name, usersid) => {
    swal
      .fire({
        title: "Do you want to delete this user?",
        text: `"${first_name} ${last_name} " will be deleted permanently.`,
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#FF0000",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiLink.post("/api/delete-user", {
              email: email,
              password: password,
              usersid: usersid,
            });

            if (response.status === 200) {
              console.log(response.data);
              if (response.data.Data.is_valid == false) {
                swal.fire({
                  title: "Error Occured. Please try again.",
                  icon: "warning",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
              } else if (response.data.Data.is_valid == true) {
                swal.fire({
                  title: "User Deleted Successfully",
                  icon: "success",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
              }

              fetchUserList();
            }
          } catch (error) {}
        }
      });
  };

  const handleRestoreUser = (first_name, last_name, usersid) => {
    swal
      .fire({
        title: "Do you want to restore this user?",
        text: `User "${first_name} ${last_name} " will be restored.`,
        showCancelButton: true,
        confirmButtonText: "Restore",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#FF0000",
      })

      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiLink.post("/api/restore-user", {
              email: email,
              password: password,
              usersid: usersid,
            });

            if (response.status === 200) {
              if (response.data.Data.success == true) {
                swal.fire({
                  title: "User Restored Successfully",
                  icon: "success",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });

                fetchUserList();
              } else if (response.data.Data.success == false) {
                swal.fire({
                  title: "Error Occured. Please try again later.",
                  icon: "warning",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
              } else {
                swal.fire({
                  title: "Unknown Error Occured. Please try again later.",
                  icon: "error",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
              }
            }
          } catch (error) {}
        }
      });
  };

  const handleUnlockUser = (first_name, last_name, usersid) => {
    swal
      .fire({
        title: "Do you want to enable this user?",
        text: `User "${first_name} ${last_name} " will be restored.`,
        showCancelButton: true,
        confirmButtonText: "Save",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#FF0000",
      })

      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiLink.post("/api/restore-user", {
              email: email,
              password: password,
              usersid: usersid,
            });

            if (response.status === 200) {
              if (response.data.Data.success == true) {
                swal.fire({
                  title: "User Enabled Successfully",
                  icon: "success",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
              } else if (response.data.Data.success == false) {
                swal.fire({
                  title: "Error Occured. Please try again later.",
                  icon: "warning",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
              } else {
                swal.fire({
                  title: "Unknown Error Occured. Please try again later.",
                  icon: "error",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
              }

              fetchUserList();
            }
          } catch (error) {}
        }
      });
  };

  return (
    <div
      className={`min-h-screen bg-gray-100 dark:bg-gray-700 transition-all duration-300 p-4 mt-20`}
      style={{ marginLeft: sideBarOpen ? "16rem" : "0" }}
    >
      <div className="p-4 bg-white shadow-md rounded-md dark:bg-gray-800">
        <div className="flex justify-between">
          <div className="p-1 mt-1 text-xl font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            Sustainable Sprinklers Users
          </div>
          {is_read_only !== 1 && (
            <div>
              <AddViewUsers fetchUserList={fetchUserList} />
            </div>
          )}
        </div>

        <div className="flex flex-col py-2 w-36 ml-2">
          <label
            htmlFor="status"
            className="block mb-0 text-sm font-semibold text-gray-900 dark:text-white"
          >
            Status
          </label>
          <select
            id="status"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            disabled={loading}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="1">Enabled</option>
            <option value="2">Pending</option>
            <option value="3">Deleted</option>
            <option value="0">Disabled</option>
          </select>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full border border-black dark:border-white animate__animated animate__fadeIn text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 leading-4">
            <thead
              className={`text-sm uppercase leading-4`}
              style={{
                backgroundColor: `${tableHeaderColor}`,
                color: `${tableHeaderTextColor}`,
              }}
            >
              <tr>
                <th scope="col" className="px-6 py-3 border border-black">
                  Name / Login ID
                </th>
                <th scope="col" className="px-6 py-3 border border-black">
                  Locations
                </th>
                <th scope="col" className="px-6 py-3 border border-black">
                  Phone
                </th>
                <th scope="col" className="px-6 py-3 border border-black">
                  User Type
                </th>

                <th scope="col" className="px-6 py-3 border border-black">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 border border-black">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="animate__animated animate__fadeIn text-sm text-black dark:text-white leading-4">
              {loading ? (
                <tr>
                  <td colSpan="7" className="h-64">
                    <div
                      role="status"
                      className="flex justify-center items-center h-full"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
              ) : userListData.length > 0 ? (
                userListData.map((userlist) => (
                  <tr
                    key={userlist.usersid}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 "
                  >
                    <td className="border border-black dark:border-white px-6 py-4">
                      <div>
                        <h1>
                          {userlist.first_name} {userlist.last_name}
                        </h1>
                        <h1>{userlist.email}</h1>
                      </div>
                    </td>

                    <td className="border border-black dark:border-white  pl-6">
                      {renderCompanyLocationNames(userlist.company_location_id)}
                    </td>

                    <td className="border border-black dark:border-white px-6 py-4">
                      {userlist.phone}{" "}
                    </td>
                    <td className="border border-black dark:border-white px-6 py-4">
                      <div>{USER_TYPES[userlist.usertype]}</div>{" "}
                      <div>
                        {userlist.is_read_only === 1 ? "Read Only" : ""}
                      </div>
                    </td>
                    <td className="border border-black dark:border-white px-6 py-4 text-center relative">
                      <div className="flex items-center">
                        <div
                          className={`status p-2.5 rounded-md inline-block ${
                            userlist.status === 0
                              ? "bg-red-300 text-red-900"
                              : userlist.status === 1
                              ? "text-white"
                              : userlist.status === 2
                              ? "bg-blue-300 text-blue-900"
                              : userlist.status === 3
                              ? "bg-red-300 text-red-900"
                              : ""
                          }`}
                          style={
                            userlist.status === 1
                              ? { backgroundColor: "#74D348" }
                              : {}
                          }
                        >
                          {userlist.status === 0
                            ? "Disabled"
                            : userlist.status === 1
                            ? "Active"
                            : userlist.status === 2
                            ? "Pending"
                            : userlist.status === 3
                            ? "Deleted"
                            : ""}
                        </div>

                        {userlist.alerts_access === 1 && (
                          <div className="warning p-1 rounded-md ml-2 bg-orange-400 group relative">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="white"
                              className="size-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                              />
                            </svg>
                            <span className="tooltip invisible group-hover:visible absolute z-10 bg-gray-800 text-white text-xs rounded py-1 px-2 -mt-10 -ml-16">
                              Alerts Enabled
                            </span>
                          </div>
                        )}
                      </div>
                    </td>

                    <td className="border border-black dark:border-white px-6 py-4 md:z-50">
                      <div className={`flex items-center justify-start gap-3`}>
                        {" "}
                        <div
                          onClick={() =>
                            handleEditIcon(
                              userlist.usersid,
                              userlist.email,
                              userlist.first_name,
                              userlist.last_name,
                              userlist.phone,
                              userlist.usertype,
                              userlist.system_notifications,
                              userlist.is_read_only,
                              userlist.alerts_access,
                              userlist.alerts,
                              userlist.company_location_id,
                              userlist.company_id
                            )
                          }
                        >
                          {is_read_only !== 1 && (
                            <button
                              onClick={toggleEditUsers}
                              className="edit p-1 hover:bg-gray-300 dark:hover:bg-white rounded-full"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="blue"
                                className="size-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                />
                              </svg>
                            </button>
                          )}
                        </div>
                        {userType !== 2 && is_read_only !== 1 && (
                          <button
                            className="email p-1 hover:bg-gray-300 dark:hover:bg-white rounded-full"
                            onClick={() =>
                              toggleResetPass(
                                userlist.first_name,
                                userlist.last_name,
                                userlist.usersid,
                                userlist.email
                              )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="size-6 dark:hover:text-black"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                              />
                            </svg>
                          </button>
                        )}
                        {userlist.status === 2 &&
                        userType !== 2 &&
                        is_read_only !== 1 ? (
                          <button
                            className="unlock p-1 hover:bg-gray-300 dark:hover:bg-white rounded-full dark:hover:text-black"
                            onClick={() =>
                              handleUnlockUser(
                                userlist.first_name,
                                userlist.last_name,
                                userlist.usersid
                              )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="size-6 text-[#34495E}"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M13.5 10.5V6.75a4.5 4.5 0 1 1 9 0v3.75M3.75 21.75h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H3.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                              />
                            </svg>
                          </button>
                        ) : (
                          ""
                        )}
                        {is_read_only !== 1 && selectedStatus !== "3" && (
                          <button
                            className="p-1 hover:bg-gray-300 dark:hover:bg-white rounded-full"
                            onClick={() =>
                              handleDeleteUser(
                                userlist.first_name,
                                userlist.last_name,
                                userlist.usersid
                              )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="red"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                          </button>
                        )}
                        {userlist.status === 3 &&
                        userType !== 2 &&
                        is_read_only !== 1 ? (
                          <button
                            className="p-1 hover:bg-gray-300 dark:hover:bg-white rounded-full dark:hover:text-black"
                            onClick={() =>
                              handleRestoreUser(
                                userlist.first_name,
                                userlist.last_name,
                                userlist.usersid
                              )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="size-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                              />
                            </svg>
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center py-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="w-8 h-8 mx-auto text-gray-500 dark:text-gray-400"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="12" y1="8" x2="12" y2="12"></line>
                      <line x1="12" y1="16" x2="12.01" y2="16"></line>
                    </svg>

                    <p className="animate__animated animate__fadeIn mt-2 text-sm text-gray-500 dark:text-gray-400">
                      No users found
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {editUsers && (
        <div
          id="static-modal"
          data-modal-backdrop="static"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto"
          style={{
            // backdropFilter: "blur(2px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          {modalLoading ? (
            <div className="flex justify-center items-center">
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="relative p-4 w-full max-w-fit h-full md:h-auto">
              {/* <!-- Modal content --> */}
              <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                {/* <!-- Modal header --> */}
                <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    Edit Sustain Sprinkler User
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="defaultModal"
                    onClick={closeEditUsers}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                {/* <!-- Modal body --> */}

                <form
                  className="flex flex-col md:flex-row gap-4"
                  onSubmit={handEditSubmit}
                >
                  <div className="leftSide grid gap-4 mb-4 sm:grid-cols-2">
                    <div>
                      <label
                        htmlFor="email"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Email Address
                      </label>
                      <input
                        type="text"
                        name="email"
                        value={getHandleIconData.email || ""}
                        onChange={handleInputChange}
                        id="email"
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="first_name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        name="first_name"
                        id="first_name"
                        value={getHandleIconData.first_name || ""}
                        onChange={handleInputChange}
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="phone"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Phone
                      </label>
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        pattern="\d{3}-\d{3}-\d{4}"
                        value={getHandleIconData.phone || ""}
                        onChange={handleInputChange}
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="last_name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        name="last_name"
                        id="last_name"
                        value={getHandleIconData.last_name || ""}
                        onChange={handleInputChange}
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="system_notifications"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        System Notifications
                      </label>
                      <select
                        id="system_notifications"
                        name="system_notifications"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        value={getHandleIconData.system_notifications || "0"} // set the default value
                        onChange={handleInputChange}
                      >
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>
                    <div>
                      <label
                        htmlFor="alerts_access"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Enable Alerts
                      </label>
                      <select
                        id="alerts_access"
                        name="alerts_access"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        value={getHandleIconData.alerts_access || "0"}
                        onChange={handleInputChange}
                      >
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>
                    <div>
                      <label
                        htmlFor="usertype"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        User Type
                      </label>
                      <select
                        id="usertype"
                        name="usertype"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        value={getHandleIconData.usertype || "0"}
                        onChange={handleInputChange}
                      >
                        <option value="2">Account Admin</option>
                        <option value="4">Account User</option>
                        {userType !== 2 && (
                          <option value="1">Super Admin</option>
                        )}
                      </select>
                    </div>
                    <div>
                      <label
                        htmlFor="is_read_only"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Read Only
                      </label>
                      <select
                        id="is_read_only"
                        name="is_read_only"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        value={getHandleIconData.is_read_only || "0"}
                        onChange={handleInputChange}
                      >
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>
                    <div>
                      <label
                        htmlFor="selectLocations"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Select Locations
                      </label>

                      <div className="grid gap-4 mb-4 grid-cols-4">
                        {renderCheckboxes(
                          getHandleIconData.company_location_id
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="rightSide flex flex-col justify-between w-full md:w-auto">
                    <table className="w-fit text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-2 py-3">
                            Text
                          </th>
                          <th scope="col" className="px-2 py-3">
                            Email
                          </th>
                          <th scope="col" className="px-2 py-3">
                            Alert
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {[
                          { abbr: "M", label: "Manual Run Detected [info]" },
                          {
                            abbr: "SD",
                            label: "Clock Skew or Prog. Mod [low]",
                          },
                          {
                            abbr: "MM",
                            label: "Multiple Manual Events [high]",
                          },
                          { abbr: "NF", label: "No Feedback [high]" },
                          { abbr: "UW", label: "Unauthorized Watering [high]" },
                        ].map((alert, index) => (
                          <tr
                            key={index}
                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                          >
                            <td className="w-4 p-4">
                              <div className="flex items-center">
                                <input
                                  type="checkbox"
                                  disabled
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                              </div>
                            </td>
                            <td className="px-6 py-4">
                              <div className="flex items-center">
                                {/* {console.log(
                                  "getHandleIconData.alerts",
                                  getHandleIconData.alerts
                                )} */}
                                <input
                                  type="checkbox"
                                  checked={
                                    getHandleIconData.alerts &&
                                    getHandleIconData.alerts.includes(
                                      alert.abbr
                                    )
                                  }
                                  onChange={() => handleAlertChange(alert.abbr)}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                              </div>
                            </td>
                            <td className="pl-1 py-4">{alert.label}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="flex items-center justify-end mt-4">
                      <button
                        className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                        style={{ backgroundColor: "red" }}
                        onClick={closeEditUsers}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                        style={{ backgroundColor: "#009D01" }}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      )}

      {resetPass && (
        <div
          id="defaultModal"
          tabindex="-1"
          aria-hidden="true"
          className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full font-inter"
          style={{
            // backdropFilter: "blur(5px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="relative p-4 w-full max-w-5xl h-full md:h-auto">
            {/* <!-- Modal content --> */}
            <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
              {/* <!-- Modal header --> */}
              <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Reset Password Link
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal"
                  onClick={() => setResetPass(false)}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <table className="animate__animated animate__fadeIn w-full text-sm text-left rtl:text-right cursor-pointer font-inter leading-4">
                <tbody className="text-sm font-inter leading-4 dark:text-white">
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="px-6 py-4">User</td>
                    <td className="px-6 py-4">
                      {resetPassDetails.resetPassEmail}
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="px-6 py-4">Name</td>
                    <td className="px-6 py-4">
                      {" "}
                      {resetPassDetails.resetPassfirstName}{" "}
                      {resetPassDetails.resetPasslastName}
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="px-6 py-4">Reset Code</td>
                    <td className="px-6 py-4">
                      {resetPassDetails.resetPassVerifyCode}
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="px-6 py-4">Reset Link</td>

                    <td className="px-6 py-4 hover:underline">
                      {/* <a
                        href={`https://app.sustainablesprinkler.com/login/verify/p/1/u/${resetPassDetails.resetPassUserID}/q/${resetPassDetails.resetPassVerifyCode}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      > */}
                      <Link
                        to={`/reset-pwd/u/${resetPassDetails.resetPassUserID}/verification_code/${resetPassDetails.resetPassVerifyCode}`}
                      >
                        {`https://portal.sustainablesprinkler.io/reset-pwd/u/${resetPassDetails.resetPassUserID}/verification_code/${resetPassDetails.resetPassVerifyCode}`}
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="flex justify-end">
                <button
                  className="text-white bg-[#FF0000] font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                  onClick={() => setResetPass(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {emailReset && (
        <div
          id="defaultModal"
          tabindex="-1"
          aria-hidden="true"
          className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full font-inter"
          style={{
            // backdropFilter: "blur(5px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="relative p-4 w-full max-w-fit h-full md:h-auto">
            {/* <!-- Modal content --> */}
            <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
              {/* <!-- Modal header --> */}
              <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                <div>
                  <h1 className="text-lg font-semibold text-gray-900 dark:text-white">
                    Reset Password Link
                  </h1>
                  <h1
                    className="flex p-1 text-lg rounded-md mt-2 font-semibold text-gray-900 dark:text-white"
                    style={{ backgroundColor: "#DFF0D8", color: "#03543F" }}
                  >
                    We have sent the password reset instructions to the email on
                    file.
                  </h1>
                </div>

                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal"
                  onClick={() => setEmailReset(false)}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <table className="animate__animated animate__fadeIn w-full text-sm text-left rtl:text-right cursor-pointer font-inter leading-4">
                <tbody className="text-sm font-inter leading-4 dark:text-white">
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="px-6 py-4">User</td>
                    <td className="px-6 py-4">
                      {" "}
                      jerome@sustainablesprinkler.com
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="px-6 py-4">Name</td>
                    <td className="px-6 py-4"> Jerome Test</td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="px-6 py-4">Reset Code</td>
                    <td className="px-6 py-4"> 123456</td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="px-6 py-4">Reset Link</td>
                    <td className="px-6 py-4">
                      https://app.sustainablesprinkler.com/login/verify/p/1/u/12873/q/00170196
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
