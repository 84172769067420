import React, { useEffect, useState, useRef, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import logopicture from "../assets/Group-2394-2.png";
import FloatingSettings from "./FloatingSettings";
import { ThemeContext } from "./context/themeContext/ThemeContext";
import "animate.css";
import { useAuth } from "./context/authContext/AuthContext";
import AccountCompanyInfo from "../pages/ADMINMENU/AcountInfo/AccountCompanyInfo";
import AccountAuditActivity from "../pages/ADMINMENU/AcountInfo/AccountAuditActivity";
import ViewUsers from "../pages/ADMINMENU/AcountInfo/ViewUsers";
import ViewLocation from "../pages/ADMINMENU/AcountInfo/ViewLocation";
import Alerts from "../pages/ADMINMENU/AcountInfo/Alerts";
import Reports from "../pages/ADMINMENU/AcountInfo/Reports";
import LeakDetection from "../pages/ADMINMENU/AcountInfo/LeakDetection";
import AccountChangePassword from "../pages/ADMINMENU/AcountInfo/AvatarDropdownContent/AccountChangePassword";
import { useSelectedCompany } from "./context/apiContext/SelectedCompanyContext";
import { useParams, useHistory } from "react-router-dom";
import ApiLink from "./api/ApiLink";
import { useSelector, useDispatch } from "react-redux";
import { logoutAndClearState } from "../store/logoutAndClearState";
import CompanyType8 from "../pages/ADMINMENU/AcountInfo/CompanyType8";
import Swal from "sweetalert2";

export default function AccountAside() {
  const { companyName } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();
  const { logoutUser, email, username, lastname, password } = useAuth();
  const [accountDropdown, setAccountDropdown] = useState(true);
  const [selectedCompanyName, setSelectedCompanyName] = useState({
    companyName: null,
    company_id: null,
    city: null,
    state: null,
    zip: null,
    type: null,
  });

  const location = useLocation();
  const [activeItem, setActiveItem] = useState(() => {
    return sessionStorage.getItem("activeItem") || "dashboard"; // Default to dashboard
  });
  const sidebarRef = useRef(null);
  const [avatarDropdown, setAvatarDropdown] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const { color, textColor, backgroundColor, sideBarOpen, setSideBarOpen } =
    useContext(ThemeContext);
  const [accountData, setAccountData] = useState([]);
  const [accountName, setAccountName] = useState("");
  const [accountId, setAccountId] = useState("");
  const [accountState, setAccountState] = useState("");
  const [accountCity, setAccountCity] = useState("");
  const [accountZip, setAccountZip] = useState("");
  const [loading, setLoading] = useState(true);
  const [getAccountId, setGetAccountId] = useState({
    accountId: "",
  });
  const [landscapeType, setLandscapeType] = useState(null);
  const userType = useSelector((state) => state.auth.userType);
  const is_read_only = useSelector((state) => state.auth.is_read_only);
  const company_location_id = useSelector(
    (state) => state.auth.company_location_id
  );

  useEffect(() => {
    console.log("CompanyName from accountaside:", companyName);
  }, [companyName]);

  useEffect(() => {
    const decodedCompanyName = companyName.replace(/-/g, " ");
  }, [companyName]);

  console.log("companyLocationId", company_location_id);

  const type = selectedCompanyName.type;

  useEffect(() => {
    console.log("Company Type: ", type);
  }, []);

  const fetchLandScape = async () => {
    try {
      const response = await ApiLink.post("/api/get-land-scape-data", {
        email: email,
        password: password,
      });

      if (response.status === 200) {
        if (Array.isArray(response.data.Data)) {
          const landscapeType = response.data.Data.type;
          setLandscapeType(landscapeType);
        } else {
          setLandscapeType(null);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setLandscapeType(null);
    }
  };

  useEffect(() => {
    if (selectedCompanyName.type === 8) {
      fetchLandScape();
    }
  }, [selectedCompanyName]);

  useEffect(() => {
    const fetchCompanyList = async () => {
      if (userType !== 1) {
        return;
      }

      try {
        const response = await ApiLink.post("/api/company-info", {
          email: email,
          password: password,
          // status: 1,
        });
        console.log("Response: ", response.data.Data.Company);

        if (response.status === 200) {
          const companies = response.data.Data;
          // const matchingCompany = companies.find(
          //   (company) => company.company_id == companyName
          // );
          const matchingCompany = response.data.Data.Company;

          if (matchingCompany) {
            console.log("Matching company found:", matchingCompany);
            setSelectedCompanyName({
              companyName: matchingCompany.name,
              company_id: matchingCompany.company_id,
              city: matchingCompany.city,
              state: matchingCompany.state,
              zip: matchingCompany.zip,
              type: matchingCompany.type,
            });
          } else {
            console.log(
              "No matching company found for company_id:",
              companyName
            );
            setSelectedCompanyName({
              companyName: null,
              company_id: null,
              city: null,
              state: null,
              zip: null,
              type: null,
            });
          }
        }
      } catch (error) {
        console.error("Error fetching company list:", error);
      }
    };

    fetchCompanyList();
  }, [companyName, email, password]);

  useEffect(() => {
    if (userType !== 2) {
      const fetchAccount = async () => {
        try {
          const response = await ApiLink.post("/api/company-info", {
            email: email,
            password: password,
          });

          if (response.status === 200) {
            setAccountData(response.data);

            const {
              name: accountName,
              company_id: accountId,
              state: accountState,
              city: accountCity,
              zip: accountZip,
            } = response.data.Data.Company;

            setAccountName(accountName);
            setAccountId(accountId);
            setAccountState(accountState);
            setAccountCity(accountCity);
            setAccountZip(accountZip);
          } else {
            console.error("Error:", response.data);
          }
        } catch (error) {
          console.error("Error:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchAccount();
    }
  }, [userType, email, password]);

  useEffect(() => {
    if (userType === 2) {
      const fetchCompany = async () => {
        try {
          const response = await ApiLink.post("/api/company-info", {
            email: email,
            password: password,
          });

          if (response.status === 200) {
            setAccountData(response.data);
            console.log("Account data:", response.data.Data);
            const {
              name: companyName,
              company_id,
              city,
              state,
              zip,
              type,
            } = response.data.Data.Company;

            setSelectedCompanyName({
              companyName,
              company_id,
              city,
              state,
              zip,
              type,
            });
          } else {
            console.error("Error:", response.data);
          }
        } catch (error) {
          console.error("Error:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchCompany();
    }
  }, [userType, email, password]);

  useEffect(() => {
    const savedItem = sessionStorage.getItem("activeItem");
    if (!savedItem) {
      setActiveItem("dashboard"); // Only set if no item is saved in session storage
    }
  }, [location]);

  useEffect(() => {
    const savedTheme = localStorage.getItem("color-theme");
    if (
      savedTheme === "dark" ||
      (!savedTheme && window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      setIsDarkMode(true);
      document.documentElement.classList.add("dark");
    } else {
      setIsDarkMode(false);
      document.documentElement.classList.remove("dark");
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSideBarOpen(false);
      }
    };

    if (sideBarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sideBarOpen, setSideBarOpen]);

  useEffect(() => {
    const savedTheme = localStorage.getItem("color-theme");
    if (
      savedTheme === "dark" ||
      (!savedTheme && window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      setIsDarkMode(true);
      document.documentElement.classList.add("dark");
    } else {
      setIsDarkMode(false);
      document.documentElement.classList.remove("dark");
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSideBarOpen(false);
      }
    };

    if (sideBarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setSideBarOpen]);

  const toggleSidebar = () => {
    setSideBarOpen((prevSideBarOpen) => !prevSideBarOpen);
  };
  const toggleAvatarDropdown = () => {
    setAvatarDropdown(!avatarDropdown);
  };

  useEffect(() => {
    console.log("SideBarOpen: ", sideBarOpen);
  }, []);

  const toggleDarkMode = () => {
    if (isDarkMode) {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("color-theme", "light");
    } else {
      document.documentElement.classList.add("dark");
      localStorage.setItem("color-theme", "dark");
    }
    setIsDarkMode(!isDarkMode);
  };

  const toggleAccountDropdown = () => {
    setAccountDropdown(!accountDropdown);
  };

  const handleItemClick = (item) => {
    setActiveItem(item);
  };
  const getColorCode = (color) => {
    switch (color) {
      case "blue":
        return "#1c64f2";
      case "green":
        return "#057a55";
      case "purple":
        return "#7e3af2";
      case "red":
        return "#FF0000";
      case "yellow":
        return "#9F580A";
      case "pink":
        return "#D61F69";
      default:
        return "#057a55";
    }
  };

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      const savedItem = sessionStorage.getItem("activeItem");
      if (savedItem) {
        setActiveItem(savedItem);
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    sessionStorage.setItem("activeItem", activeItem);
  }, [activeItem]);

  const handleLogout = () => {
    dispatch(logoutAndClearState()); // Dispatch both actions
    sessionStorage.removeItem("activeItemLocation");
    sessionStorage.removeItem("activeItem");
    localStorage.removeItem("authTokens");
  };

  const handleClickGenerateCode = () => {
    // Call the global showSessionCode function
    if (window.showSessionCode) {
      window.showSessionCode();
    } else {
      console.error("showSessionCode function not found");
    }
  };

  const handleGetSupportClick = () => {
    Swal.fire({
      html: `
        <h3 style="font-size: 18px; font-weight: bold; margin-bottom: 10px;">
          Choose an option to proceed
        </h3>
        <p style="font-size: 14px; margin-bottom: 20px;">
          Generate Code for immediate processing, or Create a Ticket for support.
        </p>
      `,
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: "Generate Code",
      denyButtonText: "Create a Ticket",
      confirmButtonColor: "#62CB31",
      denyButtonColor: "#337AB7",
      customClass: {
        popup: "swal-custom-popup",
        confirmButton: "swal-custom-confirm-button",
        denyButton: "swal-custom-deny-button",
        closeButton: "swal-custom-close-button",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleClickGenerateCode();
      } else if (result.isDenied) {
        history.push("/get-support");
      }
    });
  };

  return (
    <div>
      <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <div className="flex ms-2 md:me-24">
                <img
                  src={logopicture}
                  className="h-14 w-36 me-9"
                  alt="SustainableSprinkler Logo"
                />
                <button
                  onClick={toggleSidebar}
                  data-drawer-target="logo-sidebar"
                  data-drawer-toggle="logo-sidebar"
                  aria-controls="logo-sidebar"
                  type="button"
                  className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                >
                  <span className="sr-only">Open sidebar</span>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      fillRule="evenodd"
                      d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>

            <div className="relative">
              <div className="flex items-center">
                <div className="flex items-center ms-3">
                  <div className={`hidden xl:block md:hidden`}>
                    <h1
                      className="text-3xl font-semibold mr-6"
                      style={{ color: "#9d9fa2" }}
                    >
                      Welcome {username} {lastname}
                    </h1>
                  </div>

                  <div className="p-1 mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill={`${textColor}`}
                      className="size-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.25 9a6.75 6.75 0 0 1 13.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 0 1-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 1 1-7.48 0 24.585 24.585 0 0 1-4.831-1.244.75.75 0 0 1-.298-1.205A8.217 8.217 0 0 0 5.25 9.75V9Zm4.502 8.9a2.25 2.25 0 1 0 4.496 0 25.057 25.057 0 0 1-4.496 0Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>

                  <button
                    type="button"
                    className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600 "
                    aria-expanded="false"
                    data-dropdown-toggle="dropdown-user"
                    onClick={toggleAvatarDropdown}
                  >
                    <span className="sr-only">Avatar picture</span>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="white"
                      className="size-6 w-8 h-8 rounded-full "
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>
                  </button>

                  {avatarDropdown && (
                    <div
                      className="absolute animate__animated animate__fadeIn top-10 right-0 z-50 mt-2 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
                      id="dropdown-user"
                    >
                      <div className="px-4 py-3">
                        <p className="text-sm text-gray-900 dark:text-white">
                          {username} {lastname}
                        </p>
                        <p className="text-sm text-gray-900 truncate dark:text-gray-300">
                          {email}
                        </p>
                      </div>
                      <ul className="py-1 cursor-pointer" role="none">
                        <li onClick={() => setActiveItem(`changepassword`)}>
                          <div
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                            role="menuitem"
                          >
                            Change Password
                          </div>
                        </li>
                        {/* <Link to="/get-support"> */}
                        <li onClick={handleGetSupportClick}>
                          <div
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                            role="menuitem"
                          >
                            Get Support
                          </div>
                        </li>
                        {/* </Link> */}

                        <li onClick={handleLogout}>
                          <div
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                            role="menuitem"
                          >
                            Logout
                          </div>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 min-h-screen mt-4 pt-20 transition-transform overflow-y-auto ${
          sideBarOpen ? "-translate-x-0 sm:translate-x-0" : "-translate-x-full"
        } bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700 overflow-y-auto`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-4 overflow-y-auto bg-white dark:bg-gray-800 text-base font-inter leading-5 font-normal">
          <ul className="space-y-2 font-medium cursor-pointer">
            <li className="space-y-2 font-medium border-gray-500 dark:border-gray-300">
              <div className="flex justify-center items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                {loading ? ( // Display spinner 1.5secs
                  <div
                    role="status"
                    className="flex justify-center items-center"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-7 h-7 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <div className="flex items-center text-left">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                        />
                      </svg>
                    </div>
                    <div>
                      <h1 className="ml-3 text-md font-bold">
                        {selectedCompanyName.companyName}
                      </h1>

                      <h1 className="ml-3 text-sm">
                        {selectedCompanyName.city}, {selectedCompanyName.state},{" "}
                        {selectedCompanyName.zip}
                      </h1>
                      <h1 className="ml-3 text-sm">
                        ID: {selectedCompanyName.company_id}
                      </h1>

                      {landscapeType === 8 && (
                        <button>
                          <Link to="/portfolio-account-home">
                            <h1 className="ml-3 text-xs p-1 border border-black dark:border-white rounded-md mt-2">
                              Change Account
                            </h1>
                          </Link>
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </li>
            {userType !== 2 && (
              <Link to="/accounts">
                <li
                  className="mt-2 font-medium border-t border-gray-500 dark:border-gray-300"
                  onClick={() => sessionStorage.removeItem("activeItem")}
                >
                  <div className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group text-center font-normal">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="size-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.793 2.232a.75.75 0 0 1-.025 1.06L3.622 7.25h10.003a5.375 5.375 0 0 1 0 10.75H10.75a.75.75 0 0 1 0-1.5h2.875a3.875 3.875 0 0 0 0-7.75H3.622l4.146 3.957a.75.75 0 0 1-1.036 1.085l-5.5-5.25a.75.75 0 0 1 0-1.085l5.5-5.25a.75.75 0 0 1 1.06.025Z"
                        clipRule="evenodd"
                      />
                    </svg>

                    <span className="ms-3">ADMIN MENU</span>
                  </div>
                </li>
              </Link>
            )}

            <li>
              <ul id="dropdown-example" className="py-2 space-y-2">
                {type !== 8 && (
                  <div className="pb-1">
                    <li
                      style={
                        activeItem === "dashboard"
                          ? { backgroundColor, color: textColor }
                          : {}
                      }
                      className={`flex items-center w-full p-2 transition duration-75 rounded-2xl pl-11 group ${
                        activeItem === "dashboard"
                          ? ""
                          : "text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      }`}
                      onClick={() => setActiveItem(`dashboard`)}
                    >
                      <div className="flex justify-center gap-2 items-center text-center font-normal">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z"
                            />
                          </svg>
                        </div>
                        <h1 className="whitespace-nowrap">Dashboard</h1>
                      </div>
                    </li>
                  </div>
                )}

                {type !== 8 && (
                  <div className="pb-1">
                    <li
                      style={
                        activeItem === "activitylogs"
                          ? { backgroundColor, color: textColor }
                          : {}
                      }
                      className={`flex items-center w-full p-2 transition duration-75 rounded-2xl pl-11 group ${
                        activeItem === "activitylogs"
                          ? ""
                          : "text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      }`}
                      onClick={() => setActiveItem(`activitylogs`)}
                    >
                      <div className="flex justify-center gap-2 items-center text-center font-normal">
                        <div>
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5m.75-9 3-3 2.148 2.148A12.061 12.061 0 0 1 16.5 7.605"
                            />
                          </svg>
                        </div>
                        <h1 className="whitespace-nowrap">Activity Logs</h1>
                      </div>
                    </li>
                  </div>
                )}

                {type !== 8 && (
                  <div className="pb-1">
                    <li
                      style={
                        activeItem === "viewusers"
                          ? { backgroundColor, color: textColor }
                          : {}
                      }
                      className={`flex items-center w-full p-2 transition duration-75 rounded-2xl pl-11 group ${
                        activeItem === "viewusers"
                          ? ""
                          : "text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      }`}
                      onClick={() => setActiveItem(`viewusers`)}
                    >
                      <div className="flex justify-center gap-2 items-center text-center font-normal">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
                            />
                          </svg>
                        </div>
                        <h1 className="whitespace-nowrap">View Users</h1>
                      </div>
                    </li>
                  </div>
                )}
                {type !== 8 && (
                  <div className="pb-1">
                    <li
                      style={
                        activeItem === "viewlocation"
                          ? { backgroundColor, color: textColor }
                          : {}
                      }
                      className={`flex items-center w-full p-2 transition duration-75 rounded-2xl pl-11 group ${
                        activeItem === "viewlocation"
                          ? ""
                          : "text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      }`}
                      onClick={() => setActiveItem(`viewlocation`)}
                    >
                      <div className="flex justify-center gap-2 items-center text-center font-normal">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                            />
                          </svg>
                        </div>
                        <h1 className="whitespace-nowrap">View Location</h1>
                      </div>
                    </li>
                  </div>
                )}
                {type !== 8 && (
                  <div className="pb-1">
                    <li
                      style={
                        activeItem === "leakdetection"
                          ? { backgroundColor, color: textColor }
                          : {}
                      }
                      className={`flex items-center w-full p-2 transition duration-75 rounded-2xl pl-11 group ${
                        activeItem === "leakdetection"
                          ? ""
                          : "text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      }`}
                      onClick={() => setActiveItem(`leakdetection`)}
                    >
                      <div className="flex justify-center gap-2 items-center text-center font-normal">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z"
                            />
                          </svg>
                        </div>
                        <h1 className="whitespace-nowrap">Leak Detection</h1>
                      </div>
                    </li>
                  </div>
                )}
                {userType !== 2 && type !== 8 && (
                  <div className="pb-1">
                    <li
                      style={
                        activeItem === "alerts"
                          ? { backgroundColor, color: textColor }
                          : {}
                      }
                      className={`flex items-center w-full p-2 transition duration-75 rounded-2xl pl-11 group ${
                        activeItem === "alerts"
                          ? ""
                          : "text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      }`}
                      onClick={() => setActiveItem(`alerts`)}
                    >
                      <div className="flex justify-center gap-2 items-center text-center font-normal">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                            />
                          </svg>
                        </div>
                        <h1 className="whitespace-nowrap">Alerts</h1>
                      </div>
                    </li>
                  </div>
                )}
                {userType !== 2 && type !== 8 && (
                  <li
                    style={
                      activeItem === "reports"
                        ? { backgroundColor, color: textColor }
                        : {}
                    }
                    className={`flex items-center w-full p-2 transition duration-75 rounded-2xl pl-11 group ${
                      activeItem === "reports"
                        ? ""
                        : "text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                    }`}
                    onClick={() => setActiveItem(`reports`)}
                  >
                    <div className="flex justify-center gap-2 items-center text-center font-normal">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 3v1.5M3 21v-6m0 0 2.77-.693a9 9 0 0 1 6.208.682l.108.054a9 9 0 0 0 6.086.71l3.114-.732a48.524 48.524 0 0 1-.005-10.499l-3.11.732a9 9 0 0 1-6.085-.711l-.108-.054a9 9 0 0 0-6.208-.682L3 4.5M3 15V4.5"
                          />
                        </svg>
                      </div>
                      <h1 className="whitespace-nowrap">Reports</h1>
                    </div>
                  </li>
                )}
              </ul>
            </li>
          </ul>
        </div>
      </aside>
      <FloatingSettings toggleDarkMode={toggleDarkMode} />

      <div>
        <div>
          <div>
            {" "}
            {activeItem === "dashboard" && <AccountCompanyInfo />}
            {activeItem === "activitylogs" && <AccountAuditActivity />}
            {activeItem === "viewusers" && <ViewUsers />}
            {activeItem === "viewlocation" && <ViewLocation />}
            {activeItem === "leakdetection" && <LeakDetection />}
            {activeItem === "alerts" && <Alerts />}
            {activeItem === "reports" && <Reports />}
            {activeItem === "changepassword" && <AccountChangePassword />}
            {activeItem === "companyType8" && <CompanyType8 />}
          </div>
        </div>
      </div>
    </div>
  );
}
